import request from "@/utils/request";

//列表
export function fetchList(data) {
    return request({
        url : '/user/video/list.php',
        method : 'post',
        data : data
    })
}

//批量删除
export function batchDeleteFile(data) {
    return request({
        url : '/user/video/deleteBatch.php',
        method : 'post',
        data : data
    })
}

//删除
export function deleteFile(data) {
    return request({
        url : '/user/video//delete.php',
        method : 'post',
        data : data
    })
}

//上传文件
export function uploadFile(form) {
    const data = form
    return request({
        url: '/user/video//uploadFile.php',
        method: 'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        data
    })
}

//删除上传文件
export function deleteUploadFile(data) {
    return request({
        url : '/user/video//deleteUploadFile.php',
        method : 'post',
        data : data
    })
}


//删除上传文件
export function deleteBatchUploadFile(data) {
    return request({
        url : '/user/video/deleteBatchUploadFile.php',
        method : 'post',
        data : data
    })
}

//批量设置文件标题
export function batchAddFileTitle(data) {
    return request({
        url : '/user/video/batchAddFileTitle.php',
        method : 'post',
        data : data
    })
}
